@import "modules/responsive-type.scss";


$primary: #e67280; /* MAIN COLOR */
$secondary: #850f22; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 5px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

@import url('https://fonts.googleapis.com/css?family=Aref+Ruqaa');
@import url('https://fonts.googleapis.com/css?family=Exo');
// font-family: 'Aref Ruqaa', serif;
// font-family: 'Exo', sans-serif;


// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill, 
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    // border: 1px solid #000;
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
}


html, body {
    height: 100%;
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p, ul, li {
	font-family: 'Exo 2', sans-serif;
    // font-size: 1rem;
}

h1 {
	font-family: 'Aref Ruqaa', serif;  
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2 {
	font-family: 'Aref Ruqaa', serif;
  font-size: $mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h3 {
    font-family: 'Aref Ruqaa', serif; 
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

.flash {
	display:none;
}

nav {
	z-index: 1000;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    background: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.2);

    .navbar-nav {
        >li>a {
        	font-family: 'Exo 2', sans-serif;
        	text-align: center;
			margin-top: 20px;
			display: flex;
			align-items: center;
			color: $blk;
			border-radius: $border-radius;
			transition-duration: 0.5s;

            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;

                @media (max-width: 1024px) {
                    background: transparent;
                    color: $primary;
                    outline: 0;
                }
            }
        }
    }
}

.logo {
	@media (max-width: 767px){
		max-height: 70px
	}
}
.navbar-right {

}

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

.navbar-toggle {
    margin: 15px 15px 8px 0px;
    border-radius: 4px;

	@media (max-width: 388px) {
		margin-top: 14px;
	}
}

/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid #000;
		text-align: center;
	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}
// start sections

section {
	padding: 50px 0;
	position: relative;
}

.section-a{
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.75)),url('../img/bg1.jpg');
    background: -o-linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.75)),url('../img/bg1.jpg');
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.75)),url('../img/bg1.jpg');
	background-repeat: no-repeat; 
	background-position: 50% 0%;
	background-size: cover;
	padding:200px 0;
	
	@media(max-width: 767px){
		padding: 100px 0;
		background-position: 50% 0%;
	}

	.logo2 {
		// position: relative;

		@media(max-width: 767px){
			max-width: 150px;
		}
		@media(max-width: 568px){
			max-width: 100px;
		}		

		@media(max-width: 320px){
			display: none;
		}	

	}

	h1 {
		// font-size: 3em;
		font-weight: bold;
		color: $primary;
		text-transform: capitalize;
		text-shadow: 2px 2px 0px darken($secondary, 15%);
		padding: 25px 0;
		@media(max-width: 767px){
			// font-size:1.7em;
				text-shadow: 1px 1px 0px darken($secondary, 15%);
			padding: 0;
		}
	}
}
.section-b {
	background: #B24592; /* fallback for old browsers */
	background: -webkit-linear-gradient(to left, #B24592 , #F15F79); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to left, #B24592 , #F15F79); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	h3 {
		color: lighten($wht, 25%);
		// font-size: 1.5em;
		font-weight: 100;
	}

 .img1 {
        background: url(../img/img1.jpg);
        height: 300px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        color: #fff;
        background-color: #f8f8f8;
        border: 5px solid $secondary;
    }
   .img2 {
        background: url(../img/img2.jpg);
        height: 300px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        color: #fff;
        background-color: #f8f8f8;
        border: 5px solid $secondary;
    }

    .img3 {
        background: url(../img/img3.jpg);
        height: 300px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        color: #fff;
        background-color: #f8f8f8;
        border: 5px solid $secondary;
    }

}


.section-c {
	background: -webkit-linear-gradient(rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.75)),url('../img/bg2.jpg');
	background: -o-linear-gradient(rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.75)),url('../img/bg2.jpg');
	background: linear-gradient(rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.75)),url('../img/bg2.jpg');
	background-repeat: no-repeat; 
	background-position: 50% 0%;
	background-size: cover;
	
	@media (max-width: 568px){
		background-position: 0% 0%;
	}

	@media(max-width: 320px){
		background-position: 15% 0%;
	}	

}

.section-d {
	background: #B24592; /* fallback for old browsers */
	background: -webkit-linear-gradient(to left, #B24592 , #F15F79); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to left, #B24592 , #F15F79); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	h1 {
		// font-size: 2.5em;
		font-weight: 100;
		color: lighten($wht, 25%);
	}
	 ul {
		 padding: 0;
	 }
	ul, p{
		// font-size: 1.3em;
		color: lighten($wht, 25%);
		margin: 25px 0;
		li {
			list-style: none;
		}
	}
}
// ends sections

/*cta starts */
.cta{
  padding: 20px 45px;
  background: $primary;
	color: $wht;
	margin: 10px 5px ;
	@media (max-width:767px){
		  padding: 10px 25px;
			margin: 5px ;
	}


	&:hover {
		color: $wht;
		background: lighten($secondary, 5%);
		transition: all 1s ease 0s;
	}
}

/*cta ends */

footer {
	padding: 20px 0px 20px;
	background: $secondary;
	color: $footerLinks;

	p,a {
		font-size: 14px;
	}


	p {
		@media (max-width: 568px) {
			font-size: 13px;
		}
	}

	a {
		color: $footerLinks;
		@media (max-width: 568px) {
			font-size: 13px;
		}	

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

@media (max-width: 1024) {
	input#username, input#password {
  		// font-size: 16px;
	}
}


#circle {
    width: 300px;
    height: 300px;
    -moz-border-radius: 150px;
    -webkit-border-radius: 150px;
    border-radius: 150px;
    

    @media (max-width: 991px) {
        width: 225px;
        height: 225px;
        -moz-border-radius: 150px;
        -webkit-border-radius: 150px;
        border-radius: 150px;
    }

    @media (max-width: 768px) {
        width: 200px;
        height: 200px;
        -moz-border-radius: 150px;
        -webkit-border-radius: 150px;
        border-radius: 150px;
    }
}

.shadowthis {
    text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.50);
}

.shadowthat {
    box-shadow: -2px 2px 0px 0px rgba(0, 0, 0, 0.75);
}


